@import "../scss/variables";

.table {
  display: table;
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;

  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 2px;
  border-color: $table-border-color;

  td,
  th {
    padding: 0.75rem;
    border-top: 1px solid $form-border-color;
  }

  thead {
    th {
      //display: table-cell;
      background-color: $bg-light;
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6;
      font-family: $default-font-family;
      font-size: 15px;
      font-weight: 600;
      color: $logo-dark-color;
    }
  }

  tr {
    text-align: left;

    td {
      font-size: 14px;
      height: 60px;
      white-space: nowrap;
    }

    th {
      line-height: 3rem;
    }
  }
}

tbody tr:hover {
  background-color: $table-hover-color;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .table {
    thead {
      th {
        font-size: 16px;
      }
    }

    tr td {
      font-size: 16px;
    }
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
