@import "./../../scss/variables";

.header {
  width: 100%;
  background-color: #fff;
  margin-bottom: 30px;
}

.header-top-container {
  display: flex;
  justify-content: space-between;
}

.header-burger-container {
  display: flex;
  align-items: center;
}
.header-down-container {
  display: none;
  width: 100%;
  height: 90px;
  background-color: #efefef;
}

.header-nav-container {
  display: none;
  width: 100%;
  margin: 5px auto;
  justify-content: space-between;

  a {
    margin: auto 20px;
    i {
      font-size: 25px;
    }
  }
}

.user-section-navbar{
  display: flex;
}

.admin-section-navbar{
  display: flex;
}

.header-logo {
  margin: auto;
  height: 40px;
  width: auto;
  text-align: right;
  text-decoration: none;
}
.header-logo:hover {
  transform: scale(1.02);
  cursor: pointer;
}

.header-mobile-logo{
padding: 20px;
}

%menu-item {
  color: #3f3f3f;
  display: flex;
  align-items: center;
  transition: 200ms;
  text-decoration: none;
  margin-right: 25px;
  padding: 0;
  font-weight: 600;
  font-family: $default-font-family;
  font-size: 16px;
  i {
    font-size: 30px;
    margin-right: 10px;
    margin-top: -5px;
  }
  img {
    margin: auto 10px;
    width: 32px;
    height: 32px;
  }
}

.header-nav,
.home-titel {
  @extend %menu-item;
  p {
    display: none;
  }
}

.home-app-title {
  @extend %menu-item;
  p {
    display: block;
  }
}

.header-nav:hover {
  color: $header-nav-text-hover;
  cursor: pointer;
}
.header-nav p:nth-child(2) {
  font-size: 14px;
  font-family: $default-font-family;
  font-weight: 600;
}
.header-nav:nth-child(2),
.header-nav:nth-child(3),
.header-nav:nth-child(4),
.header-nav:nth-child(5),
.header-nav:nth-child(6) {
  margin-left: 8px;
}

.header-selected {
  color: $header-nav-text-selected;
  cursor: default;
}

.reports-menu-wrapper {
  position: relative;
  margin: 0;

}

.reports-sub-menu {
  position: absolute;
  padding: 20px 0px 20px 0px;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #fff;
  border: none;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  width: 200px;
}

.reports-menu-wrapper:hover .reports-sub-menu {
  display: block;
}

.icon-size {
  font-size: 25px !important;
}

@media (min-width: 576px) {}

@media (min-width: 768px) {
  .header-down-container {
    display: flex;
  }

  .header-mobile-logo{
    display: none;
  }

  .header-burger-container {
    display: none;
  }
  .header-nav,
  .home-titel {
    p {
      display: block;
    }
  }
  .header-nav-container {
    display: flex;
    width: 100%;
    margin: 0 auto;
    a {
      i {
        font-size: 25px;
      }
      p {
        font-size: 35px;
      }
    }
  }
}

@media (min-width: 992px) {
  .header-nav-container {
    width: 80%;
    margin: 0 auto;
    a {
      i {
        font-size: 25px;
      }
      p {
        font-size: 35px;
      }
    }
  }
}

@media (min-width: 1200px) {
}
