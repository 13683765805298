@import "../../scss/typography";

.new-class-container {
  margin: 0px auto 50px auto;
  width: 100%;
  padding: 5px;
  animation: FadeIn 500ms;
}

.new-class-form {
  border: 1px solid #eaeaea;
  font-family: "Open Sans", sans-serif;
  transition: 500ms;
  padding: 5px 10px 10px 0;
  margin: 15px auto 30px auto;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .new-class-form {
    padding: 50px 20% 50px 10%;
  }
}

@media (min-width: 992px) {
  .new-class-container {
    width: 80%;
    padding: 0;
  }
}

@media (min-width: 1200px) {
}
.error {
  border: 1px solid #e50303;
}
