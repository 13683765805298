@import "../../scss/variables";

@keyframes NotFound {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.not-found {
  transition: 500ms;
  text-align: center;
  padding-top: 100px;
  font-size: 50px;
  background-color: #ff4757;
  color: white;
  height: 100vh;
  font-family: $default-font-family;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9,
    0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
    0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
    0 20px 20px rgba(0, 0, 0, 0.15);
  p {
    animation: NotFound infinite 3s;
  }
}
