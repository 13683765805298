@import "../../scss/variables";

@mixin logo-dark-color {
  color: $logo-dark-color;
}

.upcoming-class-container {
  min-height: calc(100vh - 330px);
  margin: auto;
  width: 100%;
  animation: FadeIn 500ms;
}

.explanatory-text {
  padding-bottom: 50px;
  text-align: center;
  font-size: 16px;
  font-family: $default-font-family;

  a {
    text-decoration: none;

    &:hover {
      color: $header-nav-text-hover;
    }
  }
}

.card-title {
  font-family: $default-font-family;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  color: #79d582;
  margin: 10px auto 10px auto;

  i {
    margin: auto 5px auto 5px;
    @include logo-dark-color;
  }
  .intake-name {
    @include logo-dark-color;
  }
  .content {
    display: flex;
    padding-left: 10px;
  }
}

.page-main-header-title {
  display: flex;
  padding-left: 10px;
  align-content: center;
  font-family: $default-font-family;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  color: #79d582;
  margin: 10px auto 10px auto;
  .title-text {
    @include logo-dark-color;
  }
  i {
    margin: auto 5px auto 5px;
  }
}
.city-name {
  @include logo-dark-color;
}
@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .upcoming-class-container {
    width: 100%;
  }
  .card-title {
    font-size: 18px;
    margin: 10px auto 10px 5px;
  }

  .page-main-header-title {
    display: none;
  }
}

@media (min-width: 992px) {
  .upcoming-class-container {
    width: 80%;
    margin: 0 auto;
  }
  .card-title {
    font-size: 20px;

    margin: 50px auto 10px 5px;
    .content {
      padding-left: 0px;
    }
  }
}
@media (min-width: 1200px) {
  .upcoming-class-container {
    width: 80%;
    margin: 0 auto;
  }
}
