$default-font-family: "Raleway", sans-serif;

$logo-dark-color: #3f3f3f;
$logo-light-color: #d12f2f;

$header-nav-bg: #efefef;
$header-nav-text: #3f3f3f;
$header-nav-text-hover: #d12f2f;
$header-nav-text-selected: #d12f2f;

$footer-text-color: #ececec;
$footer-bg: #3f3f3f;

$form-border-color: #dbdbdb;

$bg-light: #f8f8f8;
$text-bg-dark: #3f3f3f;
$text-bg-light: #ececec;

$text-link-color: #79d582;
$text-link-hover-color: #339966;

$text-light-color: #aeaeae;
$text-dark-color: #2b2b2b;

$text-color-dark-pale: #ececec;
$text-color-light-pale: #fcfcfc;

$classcard-border: #f1f1f1;
$classcard-day-bg: #f8f8f8;
$classcard-day-bg-pale: #130e0e;
$classcard-day-border: #d12f2f;
$classcard-day-border-pale: #ececec;

// btn-sylabus-bg:
$btn-syllabus-bg-hover: #f2f2f2;
$btn-syllabus-border: #cccccc;
// btn-sylabus-text:

$forms-title-color: #79d482;
// text-title--color :
// text-subtitle--color :
// text-subtile-light :
$classcard-weeknumber-bg: #3f3f3f;
// ========= BUTTONS COLORS =========

$btn-submit-bg: #0a81ff;
$btn-submit-bg-hover: #00aeff;
$btn-submit-border: #d0f0ff;
$btn-disable-submit: #5AAAFF;

$btn-danger-bg: #dc3545;
$btn-danger-bg-hover: #c82333;
$btn-danger-border: #bd2130;

$btn-warning-bg: #ffc107;
$btn-warning-bg-hover: #e0a800;
$btn-warning-border: #ffc107;

$btn-success-bg: #5cb85c;
$btn-success-bg-hover: #00b700;
$btn-success-border: #5cb85c;

$btn-light-bg: #f8f9fa;
$btn-light-bg-hover: #e2e6ea;
$btn-light-border: #cccccc;

// ========= ALERT COLORS =========
$alert-light-border-color: #ececec;
$alert-light-bg-color: #fff;
$alert-light-text-color: #1e1e1e;

$alert-success-border-color: #c3e6cb;
$alert-success-bg-color: #d4edda;
$alert-success-text-color: #155724;

$alert-danger-border-color: #f5c6cb;
$alert-danger-bg-color: #f8d7da;
$alert-danger-text-color: #721c24;

$alert-info-border-color: #bee5eb;
$alert-info-bg-color: #d1ecf1;
$alert-info-text-color: #0c5460;

$table-border-color: #808080;
$table-hover-color: #eaeaea;
