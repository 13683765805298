@import "../../scss/variables";
@import "../../scss/buttons";

.global-calendar-container {
  position: relative;
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.30);
  border-radius: 7px;
  margin: 3rem 3rem;
  padding: 0;
}

.global-calendar-table {
  border-spacing: 0;
  border-collapse: separate;
  font-family: $default-font-family;
  table-layout: fixed;
  text-align: center;
  min-width: 100%;

  th[scope="row"], th[scope="col"]{
    font-weight: bold;
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #fafafa;
    border-collapse: separate;
    box-shadow: 1px 0 4px -3px #aeaeae;
  }

  // gives a minimum width to the table cells
  th,td{
    text-wrap: nowrap;
    padding: 0 2rem;
  }

  tbody {
    td {
      padding: 5px;
      height: 50px;
      &.holiday {
        background-color: rgba(255, 0, 0, 0.176);
      }
    }

    tr {
      font-size: 13px;
      height: 25px;

      &:nth-child(odd) {
        background-color: #f2f2f2;
      }
    }
  }

  thead {
    tr.table-title {
      background-color: #fafafad1 !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.931) !important;
      font-size: 13px;
      height: 60px;
    }
  }
}