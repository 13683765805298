@import "../../scss/variables";
@import "../../scss/buttons";

.users-container {
  width: 100%;
  min-height: calc(100vh - 330px);
  margin-bottom: 50px;
  animation: FadeIn 500ms;
  font-family: $default-font-family;
  font-size: 16px;
  padding: 5px 10px 10px 5px;
}

.btn-edit-user {
  @extend %btn-outline-warning;
  padding: 10px 40px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

.btn-delete-user {
  @extend %btn-outline-danger;
  padding: 10px 40px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

.hide-user-col {
  display: none;
}

@media (min-width: 992px) {
  .users-container {
    width: 80%;
    margin: 0 auto;
    padding: 0;
  }

  .hide-user-col {
    display: table-cell;
  }
}

tr {
  transition: 200ms;
}
