@import "../../scss/variables";

.newbooking-container {
  margin: 50px auto;
  width: 60%;
  display: flex;
  flex-direction: column;

  .form-radio {
    display: flex;
    flex-direction: column;
    height: 100%;

    input[type="radio"] {
      width: 16px;
    }
  }
  .check-box-container > label {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .newbooking-container {
    .form-radio {
      flex-direction: row;
    }
  }
}
