%btn-danger {
  background-color: $btn-danger-bg;
  border: 1px solid $btn-danger-border;
  color: white;
  transition: 200ms;
  &:hover {
    background-color: $btn-danger-bg-hover;
  }
}

%btn-outline-danger {
  background-color: white;
  color: $btn-danger-border;
  border: 1px solid $btn-danger-border;
  transition: 200ms;
  &:hover {
    background-color: $btn-danger-bg-hover;
    color: white;
  }
}

%btn-warning {
  background-color: $btn-warning-bg;
  border: 1px solid $btn-warning-border;
  color: white;
  transition: 200ms;
  &:hover {
    background-color: $btn-warning-bg-hover;
  }
}

%btn-outline-warning {
  background-color: white;
  color: $btn-warning-border;
  border: 1px solid $btn-warning-border;
  transition: 200ms;
  &:hover {
    background-color: $btn-warning-bg-hover;
    color: white;
  }
}


%btn-light {
  background-color: $btn-light-bg;
  border: 1px solid $btn-light-border;
  color: white;
  transition: 200ms;
  &:hover {
    background-color: $btn-light-bg-hover;
  }
}

%btn-outline-light {
  background-color: white;
  color: $btn-light-border;
  border: 1px solid $btn-light-border;
  transition: 200ms;
  &:hover {
    background-color: $btn-light-bg-hover;
    color: white;
  }
}

%btn-success {
  background-color: $btn-success-bg;
  border: 1px solid $btn-success-border;
  color: white;
  transition: 200ms;
  &:hover {
    background-color: $btn-success-bg-hover;
  }
}

%btn-outline-success {
  background-color: white;
  color: $btn-success-border;
  border: 1px solid $btn-success-border;
  transition: 200ms;
  &:hover {
    background-color: $btn-success-bg-hover;
    color: white;
  }
}

%btn-submit {
  background-color: $btn-submit-bg;
  border: 1px solid $btn-submit-border;
  color: white;
  transition: 200ms;
  &:hover {
    background-color: $btn-submit-bg-hover;
  }
}

%btn-send {
  background-color: $btn-success-bg-hover;
  border: 1px solid $btn-success-border;
  color: white;
  transition: 200ms;
  &:hover {
    background-color: rgb(0, 155, 0);
  }
}

%btn-cancel {
  background-color: white;
  color: $btn-danger-border;
  border: 1px solid $btn-danger-border;
  transition: 200ms;
  &:hover {
    background-color: $btn-danger-bg-hover;
    color: white;
  }
}
%btn-no-bg {
  background-color: transparent;
}
